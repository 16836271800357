// Variables
@import 'variables';

*:focus{
    outline: none !important;
    box-shadow: none !important;
}

// $primary: #B78D65;
$primary: #195790;
$light: #F8F8F8;
$dark: #252525;

$font-family-base: 'Open Sans', sans-serif;

$headings-font-family: 'Teko', sans-serif;

$body-color: #777777;

$headings-color: $dark;

$display-font-weight: 600;

$enable-responsive-font-sizes: true;

$border-color: $dark;

$input-border-color: $border-color;

$border-radius: 0px;

$border-radius-sm: $border-radius;

$border-radius-lg: $border-radius;

$link-decoration: none;

$enable-negative-margins: true;

p{
    font-size: 18px !important;
}

.filigran {
    display: block;
    position: relative;
}

.filigran::after {
    content: "";
    background: url('/img/logo.png');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}
